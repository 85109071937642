
import { defineComponent, onMounted, reactive, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as moment from "moment";
import mAxiosApi from "@/api";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { ElNotification } from 'element-plus'
import Cisco from "@/views/still/contrats/supports/CISCO.vue";

export default defineComponent({
  name: "contrats",
  components: {
    Datatable,
    SearchAndFilter,
    Cisco
  },
  props: {
    zContrat: Object,
  },
  
  emits: ["changeContrat"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      contratSelect: {},
      loaderEnabled: true,
      loadingDatatable: 0,
      initialMyList: [],
      myList: [] as any,
      dialogFormVisible: false,
      dialogCaseVisible: false,
    });

    const formDemande = reactive({
      desc: '',
    })

    const objKeepParent = {'parent': 'fsh_parent_instance', 'enfant': 'fsh_instance_number'};

    const tableHeader = ref([
      {
        name: "Produit",
        key: "fsh_product_description",
        sortable: false,
      },
      {
        name: "Période support",
        key: "fsh_date_debut",
        sortable: false,
      },
      {
        name: "Serv. Level",
        key: "fsh_service_level",
        sortable: false,
      },
      {
        name: "Garantie",
        key: "fsh_fin_garantie",
        sortable: false,
      },
      {
        name: "Site",
        key: "adr_seq",
        sortable: false,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Garantie",
        key: "type_garantie",
        label: "type_garantie",
      },
      {
        name: "Type de support",
        key: "type_support",
        label: "type_support",
      },
    ]);

    const ExcelFormat = ref({
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const isRefMajeur = (d) => {
      const listTmp = state.myList.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item.fsh_parent_instance === d.fsh_instance_number
      );
      return listTmp.length;
    }

    const openSubItem = (d) => {
      state.myList.forEach(el => {         
          if (el.fsh_instance_number === d.fsh_instance_number) {
            el.opensub = d.opensub == 0 ? 1 : 0;
          }
          if (el.fsh_parent === d.fsh_instance_number) {
            el.displayrow = d.opensub == 0 ? 1 : 2;
          }

        });      
    }

    const openSubAllItem = () => {
      state.myList.forEach(el => {         
          el.opensub = 1;
          el.displayrow = 1;
        });      
    }

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
    };

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_x_month = (d, nb) => {
      const currentDate = moment.default().add(nb, 'M');
      return moment.default(d) > currentDate;
    }

    const dateDepasse = (d) => {
      if (moment.default(d) > moment.default()) return -1;
      return 1
    };

    onMounted(async () => {
      const contratSelect: any = props.zContrat;
      state.contratSelect = contratSelect as any;
      if (contratSelect && contratSelect.code) {
        let myList = await getAxios("/getDetailContratSupport/" + contratSelect.code + '/' + contratSelect.cont_knum_constructeur);
        state.initialMyList = myList.results;
        state.myList = myList.results;  
        //console.log(contratSelect);
        state.loaderEnabled = false;
      }      
    });

    const notifContratSupport = async (data) => {
      const contratSelect: any = props.zContrat;
      mAxiosApi
        .post("notifContratSupport/" + contratSelect.code + '/' + contratSelect.cont_knum_constructeur, data)
        .then(() => {
          state.dialogFormVisible = false;  
          ElNotification({
            title: 'Succés',
            message: 'Demande de renouvellement envoyée à votre interlocuteur Stillnetwork.',
            type: 'success',
          })
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const confirmArchive = async (type) => {
      const contratSelect: any = props.zContrat;
      mAxiosApi
        .post("arhiveContrat/" + contratSelect.code + '/' + contratSelect.cont_knum_constructeur + '/' + type)
        .then(() => {
          const contratSelectTmp: any = state.contratSelect;
          contratSelectTmp.cont_x_archive = type==1 ? 1 : null;
          emit("changeContrat", contratSelectTmp);
          ElNotification({
            title: 'Archivage',
            message: 'Ce contrat est maintenant considéré comme '+ (contratSelectTmp.cont_x_archive == 1 ? 'archivé' : 'désarchivé'),
            type: 'success',
          })
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const openCases = () => {
      window.open('https://mycase.cloudapps.cisco.com/case', '_blank');
    }

    return {
      state,
      tableFilters,
      tableHeader,
      imgConst,
      ExcelFormat,
      isRefMajeur,
      openSubItem,
      openSubAllItem,
      formatDate,
      test_x_month,
      dateDepasse,
      formDemande,
      notifContratSupport,
      openCases,
      objKeepParent,
      confirmArchive
    };
  
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
